/*
 * 2022/5/26 3:06 PM
 * author: 张概
 * email: zhanggai_ok@126.com
 */

import router from "@/router"
import { Dialog } from "vant"
const CONTAINER_ENV_MAP = new Map([
  [0, "未定义"],
  [1, "浙里办"],
  [2, "支付宝"],
  [3, "微信"],
])

export default {
  // 根据日期返回星期
  getWeekByDate(date) {
    let week = ""
    switch (date.getDay()) {
      case 0:
        week = "星期日"
        break
      case 1:
        week = "星期一"
        break
      case 2:
        week = "星期二"
        break
      case 3:
        week = "星期三"
        break
      case 4:
        week = "星期四"
        break
      case 5:
        week = "星期五"
        break
      case 6:
        week = "星期六"
        break
    }
    return week
  },
  /**
   * 获取周一
   * @param date
   * @returns {Date}
   */
  getMonday(date) {
    let day = date.getDay()
    let monday = date.getTime() - (day - 1) * 24 * 3600 * 1000
    return new Date(monday)
  },
  /**
   *  获取周日
   *  @param date
   *  @returns {Date}
   */
  getSunday(date) {
    let day = date.getDay()
    let sunday = date.getTime() + (7 - day) * 24 * 3600 * 1000
    return new Date(sunday)
  },
  /**
   * 判断ios
   * @returns {boolean}
   */
  isIOS() {
    var u = navigator.userAgent
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
    return isiOS
  },
  /**
   * 判断android
   * @returns {boolean}
   * @constructor
   */
  isAndroid() {
    var u = navigator.userAgent
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android终端
    return isAndroid
  },
  /**
   * 判断字符串是否在数组中
   * @param str
   * @param arr
   * @returns {boolean}
   */
  isInArray(str, arr) {
    for (let i = 0; i < arr.length; i++) {
      if (str === arr[i]) {
        return true
      }
    }
    return false
  },
  // 判断用户是否有该路由权限
  hasPermission(path, userType) {
    // 根据url返回路由配置
    function getRouteByUrl(path) {
      return router.options.routes.filter((item) => item.path === path)[0]
    }
    return getRouteByUrl(path).meta.rules.includes(userType)
  },
  // 获取当前环境
  getContainerEnv() {
    const sUserAgent = window.navigator.userAgent.toLowerCase()
    let keyOfContainerEnv

    console.log("userAgent", sUserAgent)
    if (sUserAgent.indexOf("dtdreamweb") > -1) {
      keyOfContainerEnv = 1
    } else if (
      sUserAgent.indexOf("miniprogram") > -1 &&
      sUserAgent.indexOf("alipay") > -1
    ) {
      keyOfContainerEnv = 2
    } else if (
      sUserAgent.includes("wechat")
      //|| window.__wxjs_environment === "miniprogram"
    ) {
      keyOfContainerEnv = 3
    } else {
      keyOfContainerEnv = 0
    }

    return CONTAINER_ENV_MAP.get(keyOfContainerEnv)
  },
  /**
   * 图片旋转
   */
  rotateBase64Img(src, edg, fileName, fileType, callback) {
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext("2d")

    var imgW // 图片宽度
    var imgH // 图片高度
    var size // canvas初始大小

    if (edg % 90 !== 0) {
      console.error("旋转角度必须是90的倍数!")
      return "旋转角度必须是90的倍数!"
    }
    edg < 0 && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4 // 旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } // 裁剪坐标

    var image = new Image()
    image.crossOrigin = "Anonymous"
    image.src = src

    image.onload = () => {
      imgW = image.width
      imgH = image.height
      size = imgW > imgH ? imgW : imgH

      canvas.width = size * 2
      canvas.height = size * 2
      switch (quadrant) {
        case 0:
          cutCoor.sx = size
          cutCoor.sy = size
          cutCoor.ex = size + imgW
          cutCoor.ey = size + imgH
          break
        case 1:
          cutCoor.sx = size - imgH
          cutCoor.sy = size
          cutCoor.ex = size
          cutCoor.ey = size + imgW
          break
        case 2:
          cutCoor.sx = size - imgW
          cutCoor.sy = size - imgH
          cutCoor.ex = size
          cutCoor.ey = size
          break
        case 3:
          cutCoor.sx = size
          cutCoor.sy = size - imgW
          cutCoor.ex = size + imgH
          cutCoor.ey = size + imgW
          break
      }

      ctx.translate(size, size)
      ctx.rotate((edg * Math.PI) / 180)
      ctx.drawImage(image, 0, 0)

      var imgData = ctx.getImageData(
        cutCoor.sx,
        cutCoor.sy,
        cutCoor.ex,
        cutCoor.ey,
      )

      if (quadrant % 2 === 0) {
        canvas.width = imgW
        canvas.height = imgH
      } else {
        canvas.width = imgH
        canvas.height = imgW
      }

      ctx.putImageData(imgData, 0, 0)
      // 压缩图片
      // this.dealImage(canvas.toDataURL(), 1400, (base64) => {
      //     // callback(this.dataURLtoFile(base64, fileName, fileType))
      //     console.log("压缩后", base64.length / 1024)
      //     callback(base64)
      // })
      // callback(this.dataURLtoFile(canvas.toDataURL(), fileName, fileType))
      callback(canvas.toDataURL())
    }
  },
  dealImage(base64, w, callback) {
    var newImage = new Image()
    var quality = 0.6 //压缩系数0-1之间
    newImage.src = base64
    newImage.setAttribute("crossOrigin", "Anonymous") //url为外域时需要
    var imgWidth, imgHeight
    newImage.onload = function () {
      imgWidth = this.width
      imgHeight = this.height
      var canvas = document.createElement("canvas")
      var ctx = canvas.getContext("2d")
      if (Math.max(imgWidth, imgHeight) > w) {
        if (imgWidth > imgHeight) {
          canvas.width = w
          canvas.height = (w * imgHeight) / imgWidth
        } else {
          canvas.height = w
          canvas.width = (w * imgWidth) / imgHeight
        }
      } else {
        canvas.width = imgWidth
        canvas.height = imgHeight
        quality = 0.6
      }
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(this, 0, 0, canvas.width, canvas.height)
      var base64 = canvas.toDataURL("image/jpeg", quality) //压缩语句
      // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
      // while (base64.length / 1024 > 150) {
      // 	quality -= 0.01;
      // 	base64 = canvas.toDataURL("image/jpeg", quality);
      // }
      // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
      // while (base64.length / 1024 < 50) {
      // 	quality += 0.001;
      // 	base64 = canvas.toDataURL("image/jpeg", quality);
      // }
      callback(base64) //必须通过回调函数返回，否则无法及时拿到该值
    }
  },
  /**
   * 将 base64 转换为 file 对象
   *    dataURL：base64 格式
   *    fileName：文件名
   *    fileType：文件格式
   */
  dataURLtoFile(dataURL, fileName, fileType) {
    const arr = dataURL.split(",")
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], fileName, { type: fileType || "image/jpg" })
  },
  // 弹窗提示
  DialogStr(jsonStr){
    Dialog.alert({
      title: '提示',
      message: JSON.stringify(jsonStr),
      confirmButtonText: '确定',
      showCancelButton: false,
    })
  },
}
