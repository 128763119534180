/*
 * @Author: kevinzgai zhanggai_ok@126.com
 * @Date: 2023-10-11 17:16:58
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-12 08:39:06
 * @FilePath: \zyt-mobile-frontend\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
// import service from "./utils/mgopReq";
import service from "./utils/request"
import moment from "moment"
import "vant/lib/index.css"
import "lib-flexible" //自适应
import "./utils/vant"
import common from "./utils/common"
import './directive/index'
import "vue-easytable/libs/theme-default/index.css"
// 引入组件库
import VueEasytable from "vue-easytable"

Vue.use(VueEasytable)

// import F2 from "@antv/f2"
// Vue.prototype.$F2 = F2
Vue.prototype.$common = common

Vue.prototype.$isDev = process.env.NODE_ENV === "development"

//适老化
ZWJSBridge.onReady(() => {
  ZWJSBridge.getUiStyle().then((res) => {
    if (res.uiStyle == "elder") {
      if (common.isIOS()) {
        document.querySelector("html").style.fontSize = 52 + "px"
      } else {
        document.querySelector("html").style.fontSize = 50 + "px"
      }
      store.commit("changeIsOld", true)
    } else {
      store.commit("changeIsOld", false)
    }
  })
})
import vueEsign from "vue-esign"
Vue.use(vueEsign)

//路由守卫
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
moment.locale("zh-cn", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
})
Vue.prototype.moment = moment
Vue.prototype.$axios = service
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
