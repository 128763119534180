// TODO 发布前要放开
// import service from "./mgopReq";
import service from '@/utils/request'
import { Dialog } from 'vant'

export default {
  /**
   *  用户登录
   * @param params
   * @returns {Promise<unknown>}
   */
  login(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/test/login',
        url: 'mgop.wasu.zyt.test0login',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  singleSignOn(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/zlb/mobile/singleSignOn',
        url: 'mgop.wasu.zyt.zlb0mobile0singleSignOn',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取Banner列表信息
   * @param {Object} params banner用途 bannerUse: 1  （首页：1；农村放映：2）
   * @author zhangGai
   * @return {Promise}
   */
  getBanner(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/banner/mobile/show',
        url: 'mgop.wasu.zyt.banner0mobile0show',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 获取用户信息
   * @author zhangGai
   * @return {Promise}
   * @returns {Promise<unknown>}
   */

  getUserInfo() {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/pub/mobile/token/userInfo',
        url: 'mgop.wasu.zyt.user0mobile0info',
        method: 'get',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },



  /**
   *   获取放映任务
   *   @author zhangGai
   *   @param params
   *   @returns {Promise<unknown>}
   */
  getFilmTask(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/filmtask/mobile/list',
        url: 'mgop.wasu.zyt.filmtask0mobile0list',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   *  放映员-取消放映任务
   *  @author zhangGai
   *  @param params
   *  @returns {Promise<unknown>}
   *  @returns {Promise<unknown>}
   */
  cancelFilmTask(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/filmtask/mobile/cancel',
        url: 'mgop.wasu.zyt.filmtask0mobile0cancel',
        method: 'post',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 放映员-修改放映任务
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  updateFilmTask(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/filmtask/mobile/update',
        url: 'mgop.wasu.zyt.filmtask0mobile0update',
        method: 'post',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取放映评价列表
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  getFilmEvaluationList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/pub/mobile/planEvaluates',
        url: 'mgop.wasu.zyt.filmevaluation0mobile0list',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 提交放映评价
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  submitFilmEvaluation(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/pub/mobile/planEvaluates',
        url: 'mgop.wasu.zyt.filmevaluation0mobile0submit',
        method: 'post',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 企业接口
   * @author zhangGai
   */
  Company: {
    /**
     *  获取公司详情
     *  @author zhangGai
     *  @param id
     */
    getCompanyByID(id) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/ycc/query/companyByID',
          url: 'mgop.wasu.zyt.ycc0query0companyByID',
          method: 'get',
          data: {
            id,
          },
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     *  获取公映许可证列表
     *  @author zhangGai
     *  @param params
     */
    getCompanyLicenseList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/ycc/query/filmGyxkz/byCompany',
          url: 'mgop.wasu.zyt.ycc0query0filmGyxkz0byCompany',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     *  获取备案立项列表
     *  @author zhangGai
     *  @param params
     */
    getCompanyBALXList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/ycc/query/filmBags/byCompany',
          url: 'mgop.wasu.zyt.ycc0query0filmBags0byCompany',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },

  // 个人活动业务
  personal: {
    /**
     *  获取个人观影日历记录
     *  @author zhangGai
     *   @param params
     *   @returns {Promise<unknown>}
     */
    getPersonalCalendar(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/pub/mobile/filmWish/page',
          url: 'mgop.wasu.zyt.personalCalendar0mobile0list',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     *  观影记录-我想看
     *  @author zhangGai
     *  @param params {Object}
     *  @returns {Promise<unknown>}
     */
    getPersonalWantSee(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/pub/mobile/filmWish',
          url: 'mgop.wasu.zyt.personalWantSee0mobile0list',
          method: 'post',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  },
  // 集体代表业务
  groupRepresent: {
    // 获取待审批报告列表
    getReportList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/pub/mobile/showReportAudit/page',
          url: 'mgop.wasu.zyt.groupRepresent0query0list',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 获取集体代表点播记录
     * @param {*} params
     * @returns
     */
    getGroupRepresentRecord(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/pub/groupClicks/page',
          url: 'mgop.wasu.zyt.groupRepresent0query0record',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 集体代表-提交签字提交报告
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    submitReport(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: `/v3/pub/mobile/showReports/${params.showReportId}/audit`,
          url: 'mgop.wasu.zyt.groupRepresent0create0report',
          method: 'post',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
  // 放映员业务
  filmmaker: {

    /**
     * 获取放映员点播记录表-日历列表
     * @author zhangGai
     * @param params
     * @return {Promise<unknown>}
     */
    getScreeningDateList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/filmTeamClick/mobile/calendar',
          url: 'mgop.wasu.zyt.filmTeamClick0mobile0list',
          method: 'get',
          data: params,
        })
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
      })
    },
    /**
     * 获取放映员点播记录表-记录列表
     * @author zhangGai
     * @param params
     * @return {Promise<unknown>}
     */
    getScreeningRecordList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/filmTeamClick/mobile/record',
          url: 'mgop.wasu.zyt.filmTeamClick0mobile0list',
          method: 'get',
          data: params,
        })
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
      })
    },

    // 取消点播记录
    cancelScreeningRecord(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: `/v3/filmTeamClick/mobile/${params.id}/cancel`,
          url: 'mgop.wasu.zyt.filmTeamClick0mobile0cancel',
          method: 'post',
          data: params,
        })
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
      })
    },
    /**
     * 放映员-获取放映点列表
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    getShowPointsMine(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: 'v3/pub/mobile/showPoints/mine',
          url: 'mgop.wasu.zyt.filmplan0mobile0showPoints',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 获取放映员放映计划确认单
     * @author zhangGai
     * @return {Promise}
     */
    getPlanConfirmList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/mobile/filmPlans/page',
          url: 'mgop.wasu.zyt.filmplan0mobile0list',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 放映员-获取放映点所有放映员
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    getShowPointsAll(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/pub/mobile/filmTeams/page',
          url: 'mgop.wasu.zyt.filmplan0mobile0showPoints',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 放映计划-确认放映计划
     * @param params
     * @returns {Promise<unknown>}
     */
    confirmPlan(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: `/v3/mobile/filmPlans/${params.planId}/confirm`,
          url: 'mgop.wasu.zyt.filmplan0mobile0confirm',
          method: 'post',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     *  放映计划-提交修改
     *  @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    updatePlan(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: `/v3/mobile/filmPlans/${params.planId}`,
          url: 'mgop.wasu.zyt.filmplan0mobile0update',
          method: 'post',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
      /**
   * 放映计划-取消计划
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
    cancelPlan(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: `/v3/mobile/filmPlans/${params.planId}/cancel`,
          url: 'mgop.wasu.zyt.filmplan0mobile0cancel',
          method: 'post',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     *  获取放映设备列表
     *  @author zhangGai
     *  @param params
     */
    getFilmDeviceList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/pub/mobile/playDevices/page',
          url: 'mgop.wasu.zyt.filmplan0mobile0filmDevices',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 放映报告-创建报告
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    createReport(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/pub/mobile/showReports',
          url: 'mgop.wasu.zyt.groupRepresent0create0report',
          method: 'post',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 获取放映报告记录列表
    /**
     * 放映报告-获取报告记录列表
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    getReportRecordList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/v3/pub/mobile/showReports/page',
          url: 'mgop.wasu.zyt.groupRepresent0query0reportRecord',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 放映报告-获取报告详情
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    getReportDetail(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: `/v3/pub/mobile/showReports/${params.id}`,
          url: 'mgop.wasu.zyt.groupRepresent0query0reportDetail',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 放映报告-保存报告
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    updateReport(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: `/v3/pub/mobile/showReports/${params.id}`,
          url: 'mgop.wasu.zyt.groupRepresent0update0report',
          method: 'put',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
     /**
     * 放映报告-提交报告
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
     submitReport(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: `/v3/pub/mobile/showReports/${params.showReportId}/submit`,
          url: 'mgop.wasu.zyt.groupRepresent0update0report',
          method: 'post',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 放映报告-放映机记录-列表
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    getFilmLogsRecordList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: `/v3/pub/mobile/filmLogs`,
          url: 'mgop.wasu.zyt.groupRepresent0query0filmLogsRecord',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  },

  /**
   *  放映员-放映打卡
   *  @author zhangGai
   */
  checkIn: {
    /**
     * 观众-放映公告
     * place 放映地点
     * playDate 放映日期
     * pageNo 页码
     * pageSize 每页条数
     * @author zhangGai
     * @param {Object} params
     * @returns {Promise<unknown>}
     */
    getFilmBillboard(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/filmbillboard/mobile/list',
          url: 'mgop.wasu.zyt.filmbillboard0mobile0list',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     * 放映员-放映打卡-历史放映-详情
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    getFilmHistoryDetail(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/filmtaskPunch/mobile/detail',
          url: 'mgop.wasu.zyt.filmtaskPunch0mobile0detail',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     * 放映员-放映打卡-历史放映-列表
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    getFilmHistoryList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/filmtaskPunch/mobile/history',
          url: 'mgop.wasu.zyt.filmtaskPunch0mobile0history',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     * 放映员-放映打卡-今日放映-列表
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    getFilmTodayList(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/filmtaskPunch/mobile/list',
          url: 'mgop.wasu.zyt.filmtaskPunch0mobile0list',
          method: 'get',
          data: params,
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     * 放映员-放映打卡-今日放映-提交
     * @author zhangGai
     * @param params
     * @returns {Promise<unknown>}
     */
    submitFilmToday(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/filmtaskPunch/mobile/submit',
          url: 'mgop.wasu.zyt.filmtaskPunch0mobile0submit',
          method: 'post',
          data: params,
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     *  放映员-放映打卡-历史放映-更新
     *  @author zhangGai
     *  @param params
     *  @returns {Promise<unknown>}
     */
    updateFilmHistory(params) {
      return new Promise((resolve, reject) => {
        service({
          apiUrl: '/filmtaskPunch/mobile/update',
          url: 'mgop.wasu.zyt.filmtaskPunch0mobile0update',
          method: 'post',
          data: params,
        })
          .then((res) => {
            resolve(res.result)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },

  /**
   * 获取城市列表
   * @author zhangGai
   */
  getCityList() {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/regionArea/getCityList',
        url: 'mgop.wasu.zyt.regionArea0getCityList',
        method: 'get',
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 获取子区域地点
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  getChildList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/regionArea/getChildList',
        url: 'mgop.wasu.zyt.regionArea0getChildList',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /*
   * 获取社区放映点
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  getShowPointList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/pub/mobile/surround/showPoints',
        url: 'mgop.wasu.zyt.showPoint0mobile0list',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取放映点详情
   * @author zhangGai
   * @param showPointId 放映点ID
   * @returns {Promise<unknown>}
   */
  getShowPointDetail(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: `/v3/pub/mobile/showPoints/${params.id}/schedules`,
        url: 'mgop.wasu.zyt.showPoint0mobile0detail',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取拼团列表
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  getGroupList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/personalClick/mobile/groupBookingList',
        url: 'mgop.wasu.zyt.personalClick0mobile0groupBookingList',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 加入拼团
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  joinGroup(personalClickId) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: `/v3/personalClick/mobile/${personalClickId}/join`,
        url: 'mgop.wasu.zyt.personalClick0mobile0saveGroupBooking',
        method: 'post',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 提交个人点播
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  submitPersonalClick(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/personalClick/mobile/add',
        url: 'mgop.wasu.zyt.personalClick0mobile0add',
        method: 'post',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 提交个人点播
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  submitPersonalClickV3(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/personalClick/mobile/add',
        url: 'mgop.wasu.zyt.personalClick0mobile0add',
        method: 'post',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取个人点播记录列表
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  getPersonalClickList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/personalClick/mobile/record',
        url: 'mgop.wasu.zyt.personalClick0mobile0myClick',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取影片列表
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  getFilmList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/film/mobile/list',
        url: 'mgop.wasu.zyt.film0mobile0list',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 执行团体提交
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  submitGroupClick(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/pub/mobile/groupClicks',
        url: 'mgop.wasu.zyt.groupClick0mobile0add',
        method: 'post',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 获取当前日期ID计划影片列表
   * @author zhangGai
   * @param id
   * @returns {Promise<unknown>}
   */
  getFilmListByDateID(id) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/filmTeamClick/mobile/movieList',
        url: 'mgop.wasu.zyt.filmTeamClick0mobile0movieList',
        method: 'get',
        data: {
          id,
        },
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 根据id返回影片详细信息
   * @author zhangGai
   * @param param
   * @returns {Promise<unknown>}
   */
  getFilmDetail(param) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/pub/mobile/films/'+param.id,
        url: 'mgop.wasu.zyt.film0mobile0queryById',
        method: 'get',
        data: param,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 获取类型推荐Top10
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  getRecommendTop10(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/film/mobile/recommendTopTen',
        url: 'mgop.wasu.zyt.film0mobile0recommendTopTen',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 获取群众点播影片推荐
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  getViewRecommend(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/filmTeamClick/mobile/viewRecommend',
        url: 'mgop.wasu.zyt.filmTeamClick0mobile0viewRecommend',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 获取专题列表
   * @param params
   * @returns {Promise<unknown>}
   */
  getThemeList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/film/mobile/themeList',
        url: 'mgop.wasu.zyt.film0mobile0themeList',
        method: 'get',
        data: params,
      })
        .then((res) => resolve(res.result))
        .catch((error) => reject(error))
    })
  },
  
  /**
   * 获取专题详情
   * @param params
   * @returns {Promise<unknown>}
   */
  getThemeDetail(params) {
    return new Promise((resolve, reject) => { 
      service({
        apiUrl: `/v3/pub/mobile/filmThemes/${params.id}`,
        url: 'mgop.wasu.zyt.film0mobile0themeDetail',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },



  /**
   * 提交放映员点播
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  submitScreeningClick(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/filmTeamClick/mobile/submit',
        url: 'mgop.wasu.zyt.filmTeamClick0mobile0submit',
        method: 'post',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取集体账户信息
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  getGroupAccount(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/group/mobile/queryDetail',
        url: 'mgop.wasu.zyt.group0mobile0queryDetail',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 获取放映任务记录
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  getScreeningTaskList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/evaluate/mobile/taskList',
        url: 'mgop.wasu.zyt.evaluate0mobile0taskList',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res.result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 提交放映任务评价评分
   * @author zhangGai
   * @param params
   * @returns {Promise<unknown>}
   */
  submitScreeningTask(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/evaluate/mobile/evaluate',
        url: 'mgop.wasu.zyt.evaluate0mobile0evaluate',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 用户访问表记录添加
   * @param params
   * @returns {Promise<unknown>}
   */
  addUniqueVisitor(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/buriedDataStatistics/addUniqueVisitor',
        url: 'mgop.wasu.zyt.buriedDataStatistics0addUniqueVisitor',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 页面访问表记录添加
   * @param params
   * @returns {Promise<unknown>}
   */
  addPageView(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/buriedDataStatistics/addPageView',
        url: 'mgop.wasu.zyt.buriedDataStatistics0addPageView',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取用户最后一次提交的地址
   * @param params
   * @returns {Promise<unknown>}
   */
  getLastPlayPlace(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/filmTeamClick/mobile/getLastPlayPlace',
        url: 'mgop.wasu.zyt.filmTeamClick0mobile0getLastPlayPlace',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取集体最后一次提交的地址
   * @param params
   * @returns {Promise<unknown>}
   * @author zhangGai
   */
  getLastGroupPlace(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/groupClick/mobile/lastTime',
        url: 'mgop.wasu.zyt.groupClick0mobile0lastTime',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取加密后的图片地址
   * @param params
   * @returns {Promise<unknown>}
   * @author zhangGai
   */
  getNewImgUrl(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/sys/common/getNewUrl',
        url: '',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取周边放映列表
   * @param params
   * @returns {Promise<unknown>}
   */
  getFilmAroundList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/pub/mobile/surround/films',
        url: 'mgop.wasu.zyt.filmtaskPunch0mobile0around',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // 获取周边放映-更多
  /**
   * 获取周边放映列表
   * @param params
   * @returns {Promise<unknown>}
   */
  getMoreFilmAroundList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/pub/mobile/surround/films/more',
        url: 'mgop.wasu.zyt.filmtaskPunch0mobile0around',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * 获取周边放映详情
   * @param filmId
   * @param params
   * @returns {Promise<unknown>}
   */
  getFilmAroundDetail(filmId, params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: `/v3/pub/mobile/films/${filmId}`,
        url: 'mgop.wasu.zyt.filmtaskPunch0mobile0around',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 获取周边5km放映点
   * @param params 经纬度
   * @returns {Promise<unknown>}
   */
  getFilmAroundPoints(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/pub/mobile/surround/showPoints',
        url: 'mgop.wasu.zyt.filmtaskPunch0mobile0around',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * 根据区域获取放映点
   * @param params ｛pageNo: 页码, pageSize: 页容量, regionAreaId: 区域｝
   * @returns {Promise<unknown>}
   */
  getShowPointsPage(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: '/v3/pub/mobile/showPoints/page',
        url: 'mgop.wasu.zyt.filmtaskPunch0mobile0around',
        method: 'get',
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}
